<template>
	<div>
		<div
            v-html="question.content"
		></div>
	</div>
</template>


<script type="text/javascript">
export default {
	name: "Html",
	inject: ["question"]
};
//
</script>
"
